import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import Container from "../Container";
import { CloseIcon, MenuIcon } from "../Icons";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

export default function NavBar() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const toggleMenu = () => setShow(!show);

  const navigateHome = () => {
    navigate('/'); // Navigate to the home page
  };


  return (
    <Container className="flex gap-20 justify-between md:justify-start items-center h-28 relative z-40">
        <img
          className="md:h-32 h-24 cursor-pointer"
          src="/assets/logo.png"
          alt="Logo"
          onClick={navigateHome}
        />
        <ul className="md:flex hidden  flex-1 justify-center gap-10 items-center ">
        <NavLink path="/#HOME" name="Home" />
        <NavLink path="/#BENEFITS" name="Benefits" />
        <NavLink path="/#ECOSYSTEM" name="Ecosystem" />
        <NavLink path="/#ROADMAP" name="Roadmap" />
        <NavLink path="/#PREICO" name="Presale" />
        <NavLink path="/#TOKENOMICS" name="Tokenomics" />
        <NavLink path="/#CONTACT" name="Contact" />      
        <NavLink path="https://careers.dopus.io" name="Careers" external={true} />
        <Link to="/giveaway">
          <EmojiEventsIcon sx={{ color: 'gold' }} />
        </Link>
        {/*<NavLink path="/#TEAM" name="Our Team" />*/}
      </ul>
      <button onClick={toggleMenu} className="md:hidden text-white text-2xl">
        <MenuIcon />
      </button>
      {show && (
        <div className="w-screen h-screen fixed inset-0 bg-background z-50 px-4">
          <div className="justify-end flex  h-28">
            <button
              onClick={toggleMenu}
              className="md:hidden text-white text-2xl"
            >
              <CloseIcon />
            </button>
          </div>

          <ul className="flex-col flex gap-6">
            <NavLink path="/#HOME" name="Home" />
            <NavLink path="/#BENEFITS" name="Benefits" />
            <NavLink path="/#ECOSYSTEM" name="Ecosystem" />
            <NavLink path="/#ROADMAP" name="Roadmap" />
            <NavLink path="/#PREICO" name="Presale" />
            <NavLink path="/#TOKENOMICS" name="Tokenomics" />
            <NavLink path="/#CONTACT" name="Contact" />
            <NavLink path="https://careers.dopus.io" name="Careers" external={true} />
            <Link to="/giveaway">
              <EmojiEventsIcon sx={{ color: 'gold' }} />
            </Link>
            {/*<NavLink path="/#TEAM" name="Our Team" />*/}
          </ul>
        </div>
      )}
    </Container>
  );
}

export const NavLink = ({ name, path, external }) => {
  return (
    <li className="text-white hover:text-app-primary cursor-pointer">
      <a
        href={path}
        target={external ? "_blank" : "_self"}
        rel={external ? "noopener noreferrer" : undefined}
      >
        {name}
      </a>
    </li>
  );
};
