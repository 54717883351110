const InstructionModal = ({ isOpen, onClose, instructions }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Modal Content */}
      <div className="bg-gray-800 p-8 rounded-md shadow-lg max-w-md w-full text-white">
        <h2 className="text-2xl font-bold mb-4 text-blue-400">Instructions</h2>
               {/* Use dangerouslySetInnerHTML to render HTML content */}
               <div
          className="text-gray-200 text-lg"
          dangerouslySetInnerHTML={{ __html: instructions }}
        />
        <button
          className="mt-4 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition duration-200 ease-in-out"
          onClick={onClose}
        >
          Close
        </button>
      </div>

      {/* Modal Overlay */}
      <div
        //className="fixed inset-0 bg-black opacity-75"
        onClick={onClose}
      ></div>
    </div>
  );
};

export default InstructionModal;
