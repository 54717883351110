import React from 'react';
import TimeTicker from 'react-time-ticker';

const Countdown = ({ isLoading, initialSeconds, isDoubleLine }) => {
  if (isLoading) {
    return null;
  }

  return (
    <>
      <TimeTicker
        initialSeconds={initialSeconds}
        showLabels={true}
        isDoubleLine={isDoubleLine}
        displayUnitsConfig={{
          days: true,
          hours: true,
          minutes: true,
          seconds: true,
        }}
        customStyles={{
          digitBg: '#222222',
          digitColor: '#FFFFFF',
          digitFontSize: '1rem',
          labelColor: '#FFFFFF',
          labelFontSize: '0.875rem',
          separatorColor: '#FFFFFF',
          labelPaddingY: '0.1rem',
          labelBg: '#0B142F',
          verticalSeparatorColor: '#0B142F',
        }}
        labels={{
          days: 'Days',
          hours: 'Hours',
          minutes: 'Minutes',
          seconds: 'Seconds',
        }}
        emphasizeLabels={false}
        /*onTimesUp={
          () => alert('Countdown finished!')
        }*/
      />
    </>
  );
};

export default Countdown;
