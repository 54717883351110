const SuccessModal = ({ isVisible, onClose ,onShare }) => {
    if (!isVisible) return null;
  
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-md shadow-lg max-w-sm w-full text-center">
          <h2 className="text-2xl font-bold mb-4 text-green-600">Success!</h2>
          <p className="text-gray-700">Your entry has been successfully submitted!</p>
        {/* Buttons wrapped in a flex column */}
          <div className="flex flex-col space-y-4 mt-4">
            <button
              className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition duration-200 ease-in-out"
              onClick={onClose}
            >
              Close
            </button>

            <button
              className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition duration-200 ease-in-out"
              onClick={onShare}
            >
              Share Page (extra entries)
            </button>
          </div>
        </div>
      </div>
    );
  };
  export default SuccessModal;
