import React , { useState, useEffect } from "react";
//import { Helmet } from 'react-helmet';
import NavBar from '../../components/Layout/NavBar';
import Footer from '../../components/Layout/Footer';
import Countdown from "./components/Countdown";
import InstructionModal from "./components/InstructionModal";
import SuccessModal from './components/SuccessModal';

import Tippy from '@tippyjs/react';
import { FaInfoCircle } from 'react-icons/fa'; 
import Confetti from 'react-confetti'; 


//Firebase
import { db } from "../../firebase/firebase"; 
import { collection, query, orderBy, limit, getDocs,getDoc, doc,setDoc, updateDoc, serverTimestamp, addDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid'; 


const getFirebaseServerTime = async () => {
  try {
    const uniqueId = uuidv4();
    const docRef = doc(db, 'servertime', uniqueId); 

    await setDoc(docRef, {
      timestamp: serverTimestamp()
    });

    // Fetch the document back to get the server timestamp
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const serverTime = docSnap.data().timestamp.toDate(); // Convert Firestore timestamp to JavaScript Date
      return serverTime;
    } else {
      throw new Error("Unable to get server timestamp");
    }
  } catch (error) {
    console.error("Error fetching server timestamp:", error);
    return null;
  }
};

// Function to check if the contest is still valid
const checkContestValidity = async (endDate) => {
  try {
    const serverTime = await getFirebaseServerTime(); // Get the current server time from Firestore
    console.log('Server time:', serverTime);

    // Compare the server time with the end date of the contest
    return serverTime < endDate;
  } catch (error) {
    console.error("Error checking contest validity: ", error);
    return false;
  }
};

const CompetitionForm = () => {  
  const [isModalOpen, setIsModalOpen] = useState(false);

  // State variables for input fields
  const [walletAddress, setWalletAddress] = useState("");
  const [xUsername, setXUsername] = useState("");
  const [telegramUsername, setTelegramUsername] = useState("");

  // New state variables
  const [network, setNetwork] = useState("Sol"); 
  const [feedback, setFeedback] = useState("");
  const [getNotified, setGetNotified] = useState(true); 
  const [showPFP, setShowPFP] = useState(false); 
  const [winMessage, setWinMessage] = useState("");
  const [email, setEmail] = useState(""); 

  const [isDoubleLine, setIsDoubleLine] = useState(false);
  const [initialSeconds, setInitialSeconds] = useState(0);
  const [giveawayId, setGiveawayId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [instructionsHTML, setInstructionsHTML] = useState(""); 
  const [endDate, setEndDate] = useState(null); 
  //const [ended, setEnded] = useState(null); 
  //const [evaluated, setEvaluated] = useState(null); 
  const [isAwaitingResults, setIsAwaitingResults] = useState(false); // New state for awaiting results
  const [isEvaluationComplete, setIsEvaluationComplete] = useState(false); // New state for completed evaluation

  const [metadata, setMetadata] = useState({});
  const [confettiActive, setConfettiActive] = useState(false); 
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false); 
  
  useEffect(() => {
    const detectMetadata = () => {
        // Initialize metadata object
        const newMetadata = {};

        // Check for userAgentData support (User-Agent Client Hints API)
        if (navigator.userAgentData) {
            navigator.userAgentData.getHighEntropyValues(['platform'])
                .then(ua => {
                    newMetadata.platform = ua.platform || 'Unknown';
                    newMetadata.userAgent = ua.uaFullVersion || 'Unknown';
                    newMetadata.language = navigator.language || 'Unknown';

                    setMetadata(newMetadata);
                })
                .catch(() => {
                    // Fallback if getHighEntropyValues fails
                    newMetadata.userAgent = navigator.userAgent || 'Unknown';
                    newMetadata.language = navigator.language || 'Unknown';

                    setMetadata(newMetadata);
                });
        } else {
            // Fallback for browsers that do not support userAgentData
            newMetadata.userAgent = navigator.userAgent || 'Unknown';
            newMetadata.language = navigator.language || 'Unknown';

            setMetadata(newMetadata);
        }
    };

    detectMetadata();
}, []);


  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      if (window.innerWidth < 390) {
        setIsDoubleLine(true);
      } else {
        setIsDoubleLine(false);
      }
    };
  
    // Initial check
    handleResize();
  
    // Add event listener
    window.addEventListener('resize', handleResize);
  
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const fetchGiveawayData = async () => {
      try {
        // Query the most recent document based on the createdDate field
        const q = query(
          collection(db, 'giveaway'),
          orderBy('createdDate', 'desc'),
          limit(1)
        );
        
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const mostRecentDoc = querySnapshot.docs[0];
          const data = mostRecentDoc.data();
          const endDateFromFirestore = data.endDate.toDate(); // Convert Firestore timestamp to JavaScript Date
  
          // Get the current server time
          const serverTime = await getFirebaseServerTime(); // Fetch Firestore server timestamp
          
          if (!serverTime) {
            console.error("Unable to fetch server time.");
            return;
          }

          // Use the function to check if the contest is still valid
          const isContestValid = serverTime < endDateFromFirestore;
          const decodedInstructions = atob(data.instructions);
          setGiveawayId(mostRecentDoc.id); 
          setInstructionsHTML(decodedInstructions);

          if (data.ended === true && data.evaluated === false) {
            setIsAwaitingResults(true);
          }

          if (data.ended === true && data.evaluated === true) {
            setIsEvaluationComplete(true);
          }

          if (isContestValid) {
            const secondsLeft = Math.max(0, Math.floor((endDateFromFirestore - serverTime) / 1000)); // Calculate remaining time in seconds
  
            setInitialSeconds(secondsLeft);
            setEndDate(endDateFromFirestore);
          } else {
            console.log("The contest has expired.");
          }
        } else {
          console.log('No documents found!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching data
      }
    };
  
    fetchGiveawayData();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!giveawayId) {
      alert("No valid giveaway found.");
      return;
    }

    // Re-check contest validity before submitting the form
    const isContestStillValid = await checkContestValidity(endDate);

    if (!isContestStillValid) {
      alert("The contest has expired. You can no longer submit.");
      return;
    }

    try {
      // Create a new contestant document in the contestants subcollection
      const contestantRef = collection(db, 'giveaway', giveawayId, 'contestants');
      await addDoc(contestantRef, {
        walletAddress,
        email,
        xUsername,
        telegramUsername,
        network,
        feedback,
        getNotified,
        showPFP,
        winMessage,
        createdAt: new Date(),
        ...metadata,
      });
      
      // Optionally reset form fields after submission
      setWalletAddress("");
      setEmail(""); // Reset email field
      setXUsername("");
      setTelegramUsername("");
      setNetwork("Sol");
      setFeedback("");
      setGetNotified(true);
      setShowPFP(false);
      setWinMessage("");
      setConfettiActive(true);

      window.scrollTo({ top: 0, behavior: 'smooth' });


      setTimeout(() => {
         setConfettiActive(false);
      }, 15000);
      setIsSuccessModalVisible(true);      

    } catch (error) {
      // Error alert
      console.error("Error adding contestant: ", error);
      alert("An error occurred while submitting your entry. Please try again.");
    }
  };


  function sharePage() {
    if (navigator.share) {
      navigator.share({
        title: 'Join the Giveaway!',
        text: 'I just entered a giveaway for 1 SOL or AVAX! Join me and enter your wallet for a chance to win.',
        url: window.location.href,
      })
      .then(() => console.log('Page shared successfully!'))
      .catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Web Share API not supported on this browser.');
    }
  }

  if (isSuccessModalVisible) {
    return (
      <>
          {/* Confetti Effect */}
          {confettiActive && <Confetti />}
        
          {/* Success Modal */}
          <SuccessModal
          isVisible={isSuccessModalVisible}
          onClose={() => setIsSuccessModalVisible(false)}
          onShare = {() => sharePage()}
        />
      </>
    );
  }

  
  // Render evaluation complete page
  if (isEvaluationComplete) {
    return (
      <div className="text-center py-10 bg-gray-800 rounded-lg shadow-lg">
        <Confetti />
        <h1 className="box-heading text-2xl md:text-3xl lg:text-4xl text-yellow-400 font-bold">
          Giveaway #1 ended! 🎉
        </h1>
        <p className="mt-2 text-lg text-blue-300">
          Thanks for participating!
        </p>
        <img 
          src="assets/images/giveaway/winner1.png" 
          alt="Winner Siglum" 
          className="mt-4 w-64 h-64 mx-auto"
        />
        <p className="mt-2 text-xl text-green-400 font-semibold">
          Congratulations to our winner, Siglum! 🎊
        </p>
      </div>
    );
  }
  


// Render awaiting results page
if (isAwaitingResults) {
  return (
    <div className="text-center py-10 bg-gray-800 rounded-lg shadow-lg">
      <h1 className="box-heading text-2xl md:text-3xl lg:text-4xl text-yellow-400 font-bold">
        Giveaway #1 ended!
      </h1>
      <p className="mt-2 text-lg text-blue-300">
        We are evaluating the results and the winner will be announced soon.
      </p>
      <p className="mt-4 text-green-400 font-semibold">Good luck!</p>
    </div>
  );
}


  return (
    <form
      onSubmit={handleSubmit}
      className="competition-form box3 p-6 w-full max-w-md mx-auto"
    >
      <h1 className="box-heading text-2xl md:text-3xl lg:text-4xl">
        Giveaway #1
      </h1>

      {!isLoading && (
        <div className="countdown-container pb-5">
         <Countdown
          isLoading={isLoading}
          initialSeconds={initialSeconds}
          isDoubleLine={isDoubleLine}
          />          
        </div>
      )}

 {/* Instructions Subtitle */}
 <div className="text-center pb-5">
        <span
          className="text-blue-500 cursor-pointer underline"
          onClick={() => setIsModalOpen(true)}
        >
          Instructions
        </span>
      </div>

        {/* Confetti Effect */}
        {confettiActive && <Confetti />}
        
       {/* Success Modal */}
       <SuccessModal
        isVisible={isSuccessModalVisible}
        onClose={() => setIsSuccessModalVisible(false)}
        onShare = {() => sharePage()}
      />

      {/* Modal for Instructions */}
      <InstructionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        instructions={instructionsHTML}
      />

     {/* Preferred Network Dropdown */}
     <div className="mb-4 w-full">
        <label htmlFor="network" className="mint-txt flex items-start">
          Choose reward!
          <Tippy
            content="Select your reward. You can choose SOL or the equivalent value in AVAX."
            placement="top"
            onShow={(instance) => {
              setTimeout(() => {
                instance.hide();
              }, 3000);
            }}
          >
            <span>
              <FaInfoCircle className="ml-2 text-blue-500 cursor-pointer" />
            </span>
          </Tippy>
        </label>
        <select
          id="network"
          value={network}
          onChange={(e) => setNetwork(e.target.value)}
          className="w-full p-2 mt-1 bg-gray-800 text-white rounded-md"
        >
          <option value="Sol">Sol</option>
          <option value="Avax">Avax</option>
        </select>
      </div>

      {/* Wallet Address */}
      <div className="mb-4 w-full">
        <label htmlFor="wallet" className="mint-txt flex items-start">
          Wallet Address
          <Tippy
            content="Enter your Solana or Avax wallet address where you wish to receive the reward."
            placement="top"
            onShow={(instance) => {
              setTimeout(() => {
                instance.hide();
              }, 3000);
            }}
          >
            <span>
              <FaInfoCircle className="ml-2 text-blue-500 cursor-pointer" />
            </span>
          </Tippy>
        </label>
        <input
          type="text"
          id="wallet"
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
          required
          className="w-full p-2 mt-1 bg-gray-800 text-white rounded-md"
        />
      </div>

      {/* X Username */}
      <div className="mb-4 w-full">
      <label htmlFor="xUsername" className="mint-txt flex items-start">
      X Username (Twitter)
          <Tippy
            content="Provide your X (Twitter) username so we can verify your entry."
            placement="top"
            onShow={(instance) => {
              setTimeout(() => {
                instance.hide();
              }, 3000);
            }}
          >
            <span>
              <FaInfoCircle className="ml-2 text-blue-500 cursor-pointer" />
            </span>
          </Tippy>
        </label>
        <input
          type="text"
          id="xUsername"
          value={xUsername}
          onChange={(e) => setXUsername(e.target.value)}
          required
          className="w-full p-2 mt-1 bg-gray-800 text-white rounded-md"
        />
      </div>

      {/* Telegram Username */}
      <div className="mb-4 w-full">
        <label htmlFor="telegram" className="mint-txt flex items-start">
          Telegram Username
          <Tippy
            content="Provide your Telegram username for updates."
            placement="top"
            onShow={(instance) => {
              setTimeout(() => {
                instance.hide();
              }, 3000);
            }}
          >
            <span>
              <FaInfoCircle className="ml-2 text-blue-500 cursor-pointer" />
            </span>
          </Tippy>
        </label>
        <input
          type="text"
          id="telegram"
          value={telegramUsername}
          onChange={(e) => setTelegramUsername(e.target.value)}
          className="w-full p-2 mt-1 bg-gray-800 text-white rounded-md"
        />
      </div>

            {/* Email Input */}
            <div className="mb-4 w-full">
        <label htmlFor="email" className="mint-txt flex items-start">
          Email Address
          <Tippy content="Enter your email to stay updated." placement="top">
            <span><FaInfoCircle className="ml-2 text-blue-500 cursor-pointer" /></span>
          </Tippy>
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2 mt-1 bg-gray-800 text-white rounded-md"
        />
      </div>

      {/* Feedback Textarea */}
      <div className="mb-4 w-full">
        <label htmlFor="feedback" className="mint-txt flex items-start">
          Feedback for dOpus
          <Tippy
            content="Share your thoughts or suggestions about dOpus."
            placement="top"
            onShow={(instance) => {
              setTimeout(() => {
                instance.hide();
              }, 3000);
            }}
          >
            <span>
              <FaInfoCircle className="ml-2 text-blue-500 cursor-pointer" />
            </span>
          </Tippy>
        </label>        
        <textarea
          id="feedback"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          rows="4"
          className="w-full p-2 mt-1 bg-gray-800 text-white rounded-md"
        ></textarea>
      </div>

      {/* Get Notified Checkbox */}
      <div className="mb-4 w-full">
        <label className="flex items-start mint-txt">
          <input
            type="checkbox"
            checked={getNotified}
            onChange={(e) => setGetNotified(e.target.checked)}
            className="form-checkbox h-4 w-4 text-app-primary bg-gray-800 border-gray-600"
          />
          <span className="ml-2 text-left">
          Stay updated on the dOpus token launch.
          </span>
        </label>
      </div>

      {/* Show my PFP Checkbox */}
      <div className="mb-4 w-full">
        <label className="flex items-start mint-txt">
          <input
            type="checkbox"
            checked={showPFP}
            onChange={(e) => setShowPFP(e.target.checked)}
            className="form-checkbox h-4 w-4 text-app-primary bg-gray-800 border-gray-600"
          />
          <span className="ml-2">Show my PFP if I win</span>
        </label>
      </div>

      {/* Message if I win - Conditional Input */}
      {showPFP && (
        <div className="mb-4 w-full">
          <label htmlFor="winMessage" className="mint-txt">
          Enter a message to display if you win
          </label>
          <input
            type="text"
            id="winMessage"
            value={winMessage}
            onChange={(e) => setWinMessage(e.target.value)}
            className="w-full p-2 mt-1 bg-gray-800 text-white rounded-md"
          />
        </div>
      )}

      {/* Submit Button */}
      <div className="mt-6 flex flex-col justify-center items-center pb-16 space-y-4 mt-4">
      <button
          type="submit"
          className="btnIn px-6 py-3 text-sm md:text-base whitespace-nowrap"
        >
          Enter Giveaway
        </button>
      </div>
      <div className="mt-6 flex flex-col justify-center items-center pb-16 space-y-4 mt-4">     
          <button
            type="button"
            onClick={() => {
              sharePage()
            }}
            className="btnIn px-6 py-3 text-sm md:text-base whitespace-nowrap" 
            >
            Share This Page
          </button>
      </div>

    </form>
  );
};


const GiveawayPage = () => {
  return (
    <>
      {/* NavBar at the top */}
      <NavBar />

      {/* Helmet for external script */}
      {/*<Helmet>
        <script
          id="chatway"
          src="https://cdn.chatway.app/widget.js?id=lspFR9MzXnY0"
          async
        ></script>
      </Helmet>*/}

      {/* Main content */}
      <div className="competition-page flex flex-col items-center justify-center min-h-screen px-4 py-8">
        <div className="w-full max-w-md">
          <CompetitionForm />
        </div>
      </div>

      {/* Footer at the bottom */}
      <Footer />
    </>
  );
};

export default GiveawayPage;
