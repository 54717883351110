import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Home from "./pages/Home";
import { analytics } from './firebase/firebase';
import { logEvent } from "firebase/analytics";
import GiveawayPage from './pages/Events/Giveaway'; // The component for the competition page

function TrackPageViews() {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
    });
  }, [location]);

  return null;
}

function App() {
  useEffect(() => {
    logEvent(analytics, 'app_start');
  }, []);

  return (
    <BrowserRouter>
      <TrackPageViews />
      <div className="w-screen min-h-screen bg-background">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/giveaway" element={<GiveawayPage />} />
        </Routes>
      </div>
      <Toaster position="top-right" />
    </BrowserRouter>
  );
}

export default App;
